import React from 'react'
import styles from '../components/overlay.module.scss'
import helperstyles from '../components/helpers.module.scss'
import { ReactComponent as MailIcon } from '../components/mail-icon.svg'
import { icon as iconstyle } from '../components/mailicon.module.scss'

const ContactPage = () => (
  <div>
    <h2 className={helperstyles.srOnly}>Contact</h2>
    <p className={styles.largeText}>
      SForce
      <br />
      Christian Graumann
      <br />
      Manteuffelstraße 76
      <br />
      10999 Berlin
    </p>
    <p>
      <a href="mailto:mail@sforcemusic.com">
        <MailIcon className={iconstyle} />
      </a>
    </p>
  </div>
)

export default ContactPage
